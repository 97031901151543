import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import Layout from '../components/Layout'

export default function Datasecurity() {
  return (
    <Layout pageTitle="Data security">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <DataSecurityStyled>
        <h1>Datenschutz&shy;erkl&auml;rung</h1>
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>{' '}
        <p>
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick
          dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
          diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
          denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
          Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
          unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
        </p>
        <h3>Datenerfassung auf dieser Website</h3>{' '}
        <h4>
          Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
        </h4>{' '}
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum
          dieser Website entnehmen.
        </p>{' '}
        <h4>Wie erfassen wir Ihre Daten?</h4>{' '}
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
          in ein Kontaktformular eingeben.
        </p>{' '}
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
          Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
          automatisch, sobald Sie diese Website betreten.
        </p>{' '}
        <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{' '}
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
          Analyse Ihres Nutzerverhaltens verwendet werden.
        </p>{' '}
        <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{' '}
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
          Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
          Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
          Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft
          widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
          Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
        </p>{' '}
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
          sich jederzeit unter der im Impressum angegebenen Adresse an uns
          wenden.
        </p>
        <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>{' '}
        <p>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit sogenannten
          Analyseprogrammen.
        </p>{' '}
        <p>
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
          der folgenden Datenschutzerkl&auml;rung.
        </p>
        <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
        <h3>Externes Hosting</h3>{' '}
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die &uuml;ber eine Website generiert werden, handeln.
        </p>{' '}
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung
          gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6
          Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>{' '}
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>{' '}
        <p>Wir setzen folgenden Hoster ein:</p>
        <p>
          Netlify, Inc., located at 2325 3rd Street, Suite 296, San Francisco,
          California 94107
        </p>
        <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
        <h3>Datenschutz</h3>{' '}
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
          Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
          vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften
          sowie dieser Datenschutzerkl&auml;rung.
        </p>{' '}
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
          Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
          wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
          Zweck das geschieht.
        </p>{' '}
        <p>
          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
          (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
          aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
          durch Dritte ist nicht m&ouml;glich.
        </p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>{' '}
        <p>
          Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
          Website ist:
        </p>{' '}
        <p>
          Rebecca Frey
          <br />
          Wichernsweg 43
          <br />
          20537 Hamburg
        </p>
        <p>
          Telefon: 015255911488
          <br />
          E-Mail: rebecca@soulcoding.de
        </p>
        <p>
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
          Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
          E-Mail-Adressen o. &Auml;.) entscheidet.
        </p>
        <h3>Speicherdauer</h3>{' '}
        <p>
          Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt.
          Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
          Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
          gel&ouml;scht, sofern wir keinen anderen rechtlich
          zul&auml;ssigen&nbsp; Gr&uuml;nde f&uuml;r die Speicherung Ihrer
          personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
          Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
          L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
        </p>
        <h3>Hinweis zur Datenweitergabe in die USA</h3>{' '}
        <p>
          Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz
          in den USA eingebunden. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
          personenbezogenen Daten an die US-Server der jeweiligen Unternehmen
          weitergegeben werden. Wir weisen darauf hin, dass die USA kein
          sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind.
          US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an
          Sicherheitsbeh&ouml;rden herauszugeben, ohne dass Sie als Betroffener
          hiergegen gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht
          ausgeschlossen werden, dass US-Beh&ouml;rden (z.B. Geheimdienste) Ihre
          auf US-Servern befindlichen Daten zu &Uuml;berwachungszwecken
          verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
          Verarbeitungst&auml;tigkeiten keinen Einfluss.
        </p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{' '}
        <p>
          Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
          ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
          bereits erteilte Einwilligung jederzeit widerrufen. Die
          Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
        </p>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
          sowie gegen Direktwerbung (Art. 21 DSGVO)
        </h3>{' '}
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE
          SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
          IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
          F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE
          JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
          ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH
          EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
          MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
          SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN,
          DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
          VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG
          VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>{' '}
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>
        <h3>
          Beschwerde&shy;recht bei der zust&auml;ndigen
          Aufsichts&shy;beh&ouml;rde
        </h3>{' '}
        <p>
          Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
          Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
          insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts,
          ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
          Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
          anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>{' '}
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
          sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
          Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
          &Uuml;bertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>{' '}
        <p>
          Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
          &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
          oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
          bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
          erkennen Sie daran, dass die Adresszeile des Browsers von
          &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
          Schloss-Symbol in Ihrer Browserzeile.
        </p>{' '}
        <p>
          Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
          die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
          mitgelesen werden.
        </p>
        <h3>Auskunft, L&ouml;schung und Berichtigung</h3>{' '}
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden.
        </p>
        <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{' '}
        <p>
          Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
          jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden
          F&auml;llen:
        </p>{' '}
        <ul>
          {' '}
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
            Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
            Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>{' '}
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten
            unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
            L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
            verlangen.
          </li>{' '}
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
            sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
            Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </li>{' '}
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>{' '}
        </ul>{' '}
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
          eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
          Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
          nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
          wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union
          oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h3>Widerspruch gegen Werbe-E-Mails</h3>{' '}
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
          Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          widersprochen. Die Betreiber der Seiten behalten sich
          ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten
          Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
        </p>
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Cookies</h3>{' '}
        <p>
          Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;.
          Cookies sind kleine Textdateien und richten auf Ihrem Endger&auml;t
          keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die
          Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente
          Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden
          nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
          bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst
          l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch Ihren
          Webbrowser erfolgt.
        </p>{' '}
        <p>
          Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem
          Endger&auml;t gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese erm&ouml;glichen uns oder Ihnen die
          Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
          Cookies zur Abwicklung von Zahlungsdienstleistungen).
        </p>{' '}
        <p>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
          funktionieren w&uuml;rden (z.B. die Warenkorbfunktion oder die Anzeige
          von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
          auszuwerten&nbsp;oder Werbung anzuzeigen.
        </p>{' '}
        <p>
          Cookies, die zur Durchf&uuml;hrung des elektronischen
          Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
          bestimmter, von Ihnen erw&uuml;nschter Funktionen (funktionale
          Cookies, z. B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung
          der Website (z.B. Cookies zur Messung des Webpublikums) erforderlich
          sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert,
          sofern keine andere Rechtsgrundlage angegeben wird. Der
          Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
          Cookies zur technisch fehlerfreien und optimierten Bereitstellung
          seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
          abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
          ausschlie&szlig;lich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
          lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
        </p>{' '}
        <p>
          Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
          Setzen von Cookies informiert werden und Cookies nur im Einzelfall
          erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder
          generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
          Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der
          Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website
          eingeschr&auml;nkt sein.
        </p>{' '}
        <p>
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
          werden, werden wir Sie hier&uuml;ber im Rahmen dieser
          Datenschutzerkl&auml;rung gesondert informieren und ggf. eine
          Einwilligung abfragen.
        </p>
        <h3>Server-Log-Dateien</h3>{' '}
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          &uuml;bermittelt. Dies sind:
        </p>{' '}
        <ul>
          {' '}
          <li>Browsertyp und Browserversion</li>{' '}
          <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{' '}
          <li>Hostname des zugreifenden Rechners</li>{' '}
          <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{' '}
        </ul>{' '}
        <p>
          Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
          nicht vorgenommen.
        </p>{' '}
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der technisch fehlerfreien Darstellung und der Optimierung seiner
          Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst
          werden.
        </p>
        <h3>Kontaktformular</h3>{' '}
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r
          den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
          wir nicht ohne Ihre Einwilligung weiter.
        </p>{' '}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>{' '}
        <p>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
          entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
          Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere
          Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
        </p>
        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{' '}
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>{' '}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>{' '}
        <p>
          Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
          verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
          Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
          Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
          &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
          unber&uuml;hrt.
        </p>
        <h3>Google Analytics</h3>
        <p>
          Unsere Website verwendet Funktionen des Webanalysedienstes Google
          Analytics. Anbieter des Webanalysedienstes ist die Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA.
        </p>
        <p>
          Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die
          Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der
          Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen
          über Ihre Benutzung unserer Website werden an einen Server von Google
          übermittelt und dort gespeichert. Server-Standort ist im Regelfall die
          USA.
        </p>
        <p>
          Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein
          berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser
          Webangebot und ggf. auch Werbung zu optimieren.
        </p>
        <h3>Browser Plugin</h3>
        <p>
          Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige
          Funktionen unserer Website könnten dadurch jedoch eingeschränkt
          werden. Ebenso können Sie die Erfassung von Daten bezüglich Ihrer
          Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt anschließender
          Verarbeitung durch Google unterbinden. Dies ist möglich, indem Sie das
          über folgenden Link erreichbare Browser-Plugin herunterladen und
          installieren:{' '}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          .
        </p>
        <h3>IP-Anonymisierung</h3>
        <p>
          Wir setzen Google Analytics in Verbindung mit der Funktion
          IP-Anonymisierung ein. Sie gewährleistet, dass Google Ihre IP-Adresse
          innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          vor der Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in
          denen Google die volle IP-Adresse an einen Server in den USA überträgt
          und dort kürzt. In unserem Auftrag wird Google diese Informationen
          benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
          Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung
          und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu
          erbringen. Es findet keine Zusammenführung der von Google Analytics
          übermittelten IP-Adresse mit anderen Daten von Google statt.
        </p>
        <h2>5. Soziale Medien</h2>
        <h3>Instagram Plugin</h3>{' '}
        <p>
          Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden.
          Diese Funktionen werden angeboten durch die Facebook Ireland Limited,
          4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
          integriert.
        </p>{' '}
        <p>
          Wenn Sie in Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen Sie
          durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit
          Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch
          dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin,
          dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
          &uuml;bermittelten Daten sowie deren Nutzung durch Instagram erhalten.
        </p>{' '}
        <p>
          Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den
          Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
          wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
          widerrufbar.
        </p>{' '}
        <p>
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:{' '}
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </a>
          ,{' '}
          <a
            href="https://help.instagram.com/519522125107875"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://help.instagram.com/519522125107875
          </a>{' '}
          und{' '}
          <a
            href="https://de-de.facebook.com/help/566994660333381"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/help/566994660333381
          </a>
          .
        </p>{' '}
        <p>
          Weitere Informationen hierzu finden Sie in der
          Datenschutzerkl&auml;rung von Instagram:{' '}
          <a
            href="https://instagram.com/about/legal/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://instagram.com/about/legal/privacy/
          </a>
          .
        </p>
        <h3>XING Plugin</h3>
        <p>
          Unsere Website verwendet Funktionen des Netzwerks XING. Anbieter ist
          die XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland.
        </p>
        <p>
          Bei Aufruf einer Seite mit integrierten Xing-Funktionen wird eine
          Verbindung zu den Servern von Xing hergestellt. Eine Speicherung
          personenbezogener Daten erfolgt dabei nach unserem Kenntnisstand
          nicht. IP-Adressen werden nicht gespeichert, ebenso erfolgt keine
          Auswertung des Nutzungsverhaltens.
        </p>
        <p>
          Einzelheiten zum Datenschutz und dem XING Share-Button finden Sie in
          der Datenschutzerklärung von XING unter:{' '}
          <a href="https://www.xing.com/app/share?op=data_protection">
            https://www.xing.com/app/share?op=data_protection
          </a>
          .
        </p>
        <h2>6. Plugins und Tools</h2>
        <h3>YouTube</h3>{' '}
        <p>
          Diese Website bindet Videos der Website YouTube ein. Betreiber der
          Website ist die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon
          House, Barrow Street, Dublin 4, Irland.
        </p>{' '}
        <p>
          Wenn Sie eine unserer Webseiten besuchen, auf denen YouTube
          eingebunden ist, wird eine Verbindung zu den Servern von YouTube
          hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer
          Seiten Sie besucht haben.
        </p>{' '}
        <p>
          Des Weiteren kann YouTube verschiedene Cookies auf Ihrem Endger&auml;t
          speichern oder vergleichbare Technologien zur Wiedererkennung
          verwenden (z.B. Device-Fingerprinting). Auf diese Weise kann YouTube
          Informationen &uuml;ber Besucher dieser Website erhalten. Diese
          Informationen werden u. a. verwendet, um Videostatistiken zu erfassen,
          die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
          vorzubeugen.
        </p>{' '}
        <p>
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen
          Sie YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil
          zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
          YouTube-Account ausloggen.
        </p>{' '}
        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
          Einwilligung ist jederzeit widerrufbar.
        </p>{' '}
        <p>
          Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerkl&auml;rung von YouTube unter:{' '}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Google Web Fonts</h3>{' '}
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
          einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in
          ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
        </p>{' '}
        <p>
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
          den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
          dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen
          wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der einheitlichen Darstellung des Schriftbildes auf
          seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde
          (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
          Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit.
          a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>{' '}
        <p>
          Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine
          Standardschrift von Ihrem Computer genutzt.
        </p>{' '}
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{' '}
          und in der Datenschutzerkl&auml;rung von Google:{' '}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Bilder-Speicherdienst “Cloudinary”</h3>
        <p>
          Um diese Webseite schneller und somit perfomanter zu machen, wird der
          Bilder-Speicherdienst “Cloudinary” der Firma Cloudinary Ltd. 111 W
          Evelyn Ave, Suite 206 Sunnyvale, CA 94086, USA. eingesetzt.
        </p>
        <p>
          Cloudinary ist nach dem „Privacy Shield Framework“ zertifiziert und
          erfüllt somit die europäischen Standards für eine gesetzeskonforme
          Auftragsdatenverarbeitung. Durch den Besuch auf der Webseite erhält
          Cloudinary die Information, dass Sie die entsprechende Unterseite
          unserer Webseite aufgerufen haben. Dies erfolgt unabhängig davon, ob
          Cloudinary ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind,
          oder ob kein Nutzerkonto besteht.
        </p>
        <p>
          Die Nutzungsbedingungen von Cloudinary finden sie hier:{' '}
          <a
            href="https://cloudinary.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://cloudinary.com/privacy
          </a>
        </p>
        <p>
          Quelle:{' '}
          <a href="https://www.e-recht24.de/muster-datenschutzerklaerung.html">
            https://www.e-recht24.de/muster-datenschutzerklaerung.html
          </a>
        </p>
      </DataSecurityStyled>
    </Layout>
  )
}

const DataSecurityStyled = styled.main`
  /* margin: 0; */
  /* padding: 0; */
  width: 90vw;
  word-wrap: break-word;
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  p,
  li {
    font-size: 12px;
  }
`
